<template>
  <div>
    <!--<VDrawToolbar />-->
    <div
      no-body
      class=""
      id="canvas-container"
      ref="canvasContainer"
      v-bind:style="visibleElement"
    >
      <canvas id="detection"></canvas>
    </div>
    <b-modal
      id="create_object_modal"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      cancel-disabled
      busy
      centered
      hide-footer
      @keydown.native.enter="handleEnter"
    >
      <template #modal-title v-if="createType">
        Select <code>{{ createType.toLowerCase() }}</code> label!
      </template>
      <b-form-group label="Labels" v-if="createType != 'POINT'">
        <v-select
          v-if="createType == 'DETECTION'"
          class="mr-1"
          :options="detection_labels"
          v-model="selected_dlabel"
          :reduce="item => item.id"
          placeholder="Click to select label"
          label="name"
        >
          <template slot="option" slot-scope="option">
            <i :class="option.icon"></i>
            {{ option.name }}
          </template>
        </v-select>

        <v-select
          v-else
          class="mr-1"
          :options="segmentation_labels"
          v-model="selected_slabel"
          :reduce="item => item.id"
          placeholder="Click to select label"
          label="name"
        >
          <template slot="option" slot-scope="option">
            <i :class="option.icon"></i>
            {{ option.name }}
          </template>
        </v-select>

        <b-form-text id="input-live-help" v-if="createType == 'DETECTION'"
          >Please pick type of the object (Detection Label).</b-form-text
        >
        <b-form-text id="input-live-help" v-else
          >Please pick type of the polygon (Segmentation Label).</b-form-text
        >
      </b-form-group>
      <b-form-group
        id="fieldset-1"
        label="Enter name of point"
        label-for="input-point-name"
        v-else
      >
        <b-form-input
          id="input-point-name"
          v-model="point_name"
          trim
        ></b-form-input>
      </b-form-group>

      <b-button
        variant="outline-success"
        class="mt-3"
        @click="createEntity('activate', creatingEntity)"
        >Create</b-button
      >
      <b-button
        variant="outline-danger ml-1"
        class="mt-3"
        @click="cancelCreation()"
        >Cancel</b-button
      >
    </b-modal>

    <b-navbar class="footer border-top" style="height: 55px;">
      <b-navbar-nav>
        <b-input-group size="sm">
          <select
            class="custom-select"
            id="inputGroupSelect02"
            v-model="selected_detection_task"
            v-if="detection_tasks.length"
          >
            <option
              v-bind:key="task.id"
              v-for="task in detection_tasks"
              v-bind:value="task"
              >{{ task.name }}</option
            >
          </select>
          <b-input-group-append>
            <b-button
              variant="outline-success"
              v-on:click="predict()"
              v-if="detection_tasks.length"
            >
              <span v-if="is_predicting">
                <i class="fal fa-spinner-third fa-spin fa"></i> </span
              >Predict
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-navbar-nav>

      <b-navbar-nav>
        <b-button
          id="previous"
          :disabled="getLastImage == null"
          size="sm"
          class="ml-1"
          variant="outline-success"
          v-on:click="load_from_previous()"
        >
          Load
        </b-button>
        <b-popover
          target="previous"
          title="Load objects"
          triggers="hover focus"
          content="Load objects from previous verified image."
        ></b-popover>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <div>
          <div>
            <span v-on:click="hideItems(true)">
              <div v-show="!hide_canvas_items" id="eye" class="float-right">
                &nbsp; <i class="fal fa-lg fa-eye ml-2 mt-2"></i>
              </div>
              <div v-show="hide_canvas_items" id="eye" class="float-right">
                &nbsp; <i class="fal fa-lg fa-eye-slash ml-2 mt-2"></i>
              </div>
              <b-popover
                target="eye"
                title="Hide items"
                triggers="hover focus"
                content="Hide/Show every item on the canvas/image."
              ></b-popover>
            </span>

            <span>
              <div id="keyboard" class="float-right">
                &nbsp;
                <i
                  id="keyboard-icon"
                  class="fal fa-lg fa-keyboard ml-2 mt-2"
                ></i>
              </div>
              <b-popover
                target="keyboard"
                title="Keyboard shortcuts"
                triggers="hover focus"
                content="Use Alt (win/linux) or option (mac) + Mouse Wheel for ZOOM and Alt + Click for DRAG"
              ></b-popover>
            </span>

            <span v-on:click="eraseAll(true)" class="ml-2">
              <div id="erase" class="float-right">
                &nbsp;
                <i id="cube-icon" class="fal fa-lg fa-eraser mt-2"></i>
              </div>
              <b-popover
                target="erase"
                title="Erase all objects"
                triggers="hover focus"
                content="Click to erase all objects from image."
              ></b-popover>
            </span>

            <span v-if="foreground_mask">
              <div id="mask" class="float-right mr-2">
                &nbsp;
                <span style="color: green;">
                  <i id="mask-icon" class="fal fa-lg fa-mask mt-2"></i>
                </span>
              </div>
              <b-popover
                target="mask"
                title="Show/Hide foreground mask"
                triggers="hover focus"
              >
                <b-form-input
                  id="range-1"
                  v-model="show_mask_value"
                  type="range"
                  step="0.1"
                  min="0.0"
                  max="1.0"
                ></b-form-input>
              </b-popover>
            </span>

            <span>
              <div id="label" class="float-right mr-2">
                &nbsp; {{ image.labels.length }}
                <i id="label-icon" class="fal fa-lg fa-tag mt-2"></i>
              </div>
              <b-popover
                target="label"
                title="Labels (Recognition)"
                triggers="hover focus"
                content="Number of labels on the image."
              ></b-popover>
            </span>

            <span>
              <div id="cube" class="float-right mr-2">
                &nbsp; {{ detections.length }}
                <i id="cube-icon" class="fal fa-lg fa-draw-square mt-2"></i>
              </div>
              <b-popover
                target="cube"
                title="Objects (Detection)"
                triggers="hover focus"
                content="Number of detection objects on the image."
              ></b-popover>
            </span>

            <span>
              <div id="polygon" class="float-right mr-2">
                &nbsp; {{ segmentations.length }}
                <i id="polygon-icon" class="fal fa-lg fa-draw-polygon mt-2"></i>
              </div>
              <b-popover
                target="polygon"
                title="Polygons (Segmentation)"
                triggers="hover focus"
                content="Number of polygons on the image."
              ></b-popover>
            </span>
          </div>
        </div>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-console */

import { mapState, mapGetters } from "vuex";
import axios from "axios";
import { DataLoadedEnum, ResourceEnum } from "../../constants";

let API_URL = process.env.VUE_APP_API_URL;
let OPACITY_VALUE = 0.1;

function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

var LabeledRect = fabric.util.createClass(fabric.Rect, {
  type: "labeledRect",

  initialize: function(options) {
    options || (options = {});

    this.callSuper("initialize", options);
    this.set("id", options.id || "");
    this.set("id_label", options.id_label || "");
    this.set("label", options.label || "");
    this.set("type", "DETECTION");
    // todo
    this.set("created", options.created || "");
    this.set("is_selected", options.is_selected || "");
  },

  toObject: function() {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      label: this.get("label"),
      id: this.get("id"),
      id_label: this.get("id_label")
    });
  },

  _render: function(ctx) {
    this.callSuper("_render", ctx);

    ctx.font = "14px Roboto";
    ctx.fillStyle = "#333";
    ctx.fillText(this.label, -this.width / 2, -this.height / 2 + 14);
  }
});

var LabeledPoly = fabric.util.createClass(fabric.Polygon, {
  type: "labeledPoly",

  initialize: function(points, options) {
    options || (options = {});

    this.callSuper("initialize", points, options);
    this.set("id", options.id || "");
    this.set("id_label", options.id_label || "");
    this.set("label", options.label || "");
    this.set("type", "SEGMENTATION");
    // todo
    this.set("created", options.created || "");
    this.set("is_selected", options.is_selected || "");
  },

  toObject: function() {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      label: this.get("label"),
      id: this.get("id"),
      id_label: this.get("id_label")
    });
  }
});

var LabeledPoint = fabric.util.createClass(fabric.Circle, {
  type: "labeledPoint",

  initialize: function(options) {
    options || (options = {});

    this.callSuper("initialize", options);
    this.set("id", options.id || "");
    this.set("name", options.name || "");
    this.set("type", "POINT");
    // todo
    this.set("created", options.created || "");
    this.set("is_selected", options.is_selected || "");
  },

  toObject: function() {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      id: this.get("id"),
      name: this.get("name")
    });
  }
});

let config = {
  baseURL: "https://api.ximilar.com/",
  timeout: 10000,
  headers: {
    Authorization: "Token " //where applicable
  }
};
let axiosdev = axios.create(config);

export default {
  name: "vdetection",
  components: {},
  props: {
    job: Object,
    image: Object,
    messageAddTag: Object,
    messageRemoveTag: Object,
    messageRemovePoint: Object,
    messageRemoveDetection: Object,
    messageRemoveSegmentation: Object,
    messageUpdateMeta: Object,
    messageChangeSegmentationLabel: Object,
    messageChangeDetectionLabel: Object
  },
  data() {
    return {
      ResourceEnum: ResourceEnum,
      detections: [],
      points: [],
      segmentations: [],
      is_predicting: false,
      selected_detection_task: null,
      loading: true,
      entities: {
        objects: [],
        polygons: [],
        points: []
      },
      strokeWidth: 3,
      zoom: 1.0,
      point_name: "",
      creatingEntity: null,
      selected_dlabel: null,
      selected_slabel: null,
      active_object: null,
      mode: "SELECTION",
      last_mode: "SELECTION",
      show_mask_value: 0.0,
      foreground_mask: null,
      image_canvas: null,
      canvas: {
        element: null,
        isCreating: false,
        isDragging: false,
        lastPosX: 0, // DRAG
        lastPosY: 0,
        orixX: 0, // CREATE
        origY: 0,
        pointArray: [],
        lineArray: [],
        activeLine: [],
        activeShape: false,
        selected: false
      },
      strokeDashArray: 5,
      scaleBefore: {
        x: 0.0,
        y: 0.0
      },
      buttons: [
        { caption: "Create", mode: "CREATE", icon: "fal fa-plus", state: true }
      ]
    };
  },
  computed: {
    ...mapState([
      "hide_canvas_items",
      "tasks",
      "labels",
      "detection_labels",
      "detection_tasks",
      "segmentation_labels",
      "segmentation_tasks",
      "data_loaded",
      "workspace"
    ]),
    ...mapGetters([
      "checkResource",
      "getLastDetectionLabel",
      "getLastDetectionTask",
      "getLabelById",
      "getLastImage",
      "getLastJob"
    ]),
    windowHeight: {
      get: function() {
        return window.innerHeight;
      },
      set: function(newvalue) {}
    },
    windowWidth: {
      get: function() {
        return window.innerWidth;
      },
      set: function(newvalue) {}
    },
    visibleElement: function() {
      if (this.loading) {
        return { visibility: "hidden" };
      } else {
        return { visibility: "visible" };
      }
    },
    createType: function() {
      if (this.creatingEntity) {
        return this.creatingEntity.type;
      } else {
        return "DETECTION";
      }
    }
  },
  methods: {
    clickOutsideImage: function(options) {
      if (
        (options.pointer.x - this.canvas.element.viewportTransform[4]) /
          this.zoom >
          this.image.img_width ||
        (options.pointer.y - this.canvas.element.viewportTransform[5]) /
          this.zoom >
          this.image.img_height
      ) {
        return true;
      }

      if (
        (options.pointer.x - this.canvas.element.viewportTransform[4]) /
          this.zoom <
          0 ||
        (options.pointer.y - this.canvas.element.viewportTransform[5]) /
          this.zoom <
          0
      ) {
        return true;
      }
      return false;
    },
    selectItem(item_id) {
      this.selectObject(null, item_id);
    },
    async load_entities() {
      await this.getData(
        API_URL +
          "/detection/v2/object/?image=" +
          this.image.id +
          "&page_size=500" +
          "&workspace=" +
          this.workspace,
        "objects",
        "results"
      );

      await this.getData(
        API_URL +
          "/segmentation/v2/polygon/?image=" +
          this.image.id +
          "&page_size=500" +
          "&workspace=" +
          this.workspace,
        "polygons",
        "results"
      );

      await this.getData(
        API_URL +
          "/annotate/v2/point/?image=" +
          this.image.id +
          "&page_size=500" +
          "&workspace=" +
          this.workspace,
        "points",
        "results"
      );

      await this.createCanvas();
    },
    hideItems: function(toggle) {
      if (toggle) {
        this.$store.dispatch("toggleHideCanvasItems");
      }

      let objects = this.canvas.element.getObjects();
      this.deactivateObject();

      for (let j = objects.length - 1; j >= 0; j--) {
        if (
          objects[j].type == "DETECTION" ||
          objects[j].type == "SEGMENTATION"
        ) {
          objects[j].set({ opacity: this.hide_canvas_items ? 0 : 1 });
        } else if (
          objects[j].opacity == OPACITY_VALUE &&
          this.hide_canvas_items
        ) {
          objects[j].set({ opacity: 0 });
        } else if (objects[j].opacity == 0 && !this.hide_canvas_items) {
          objects[j].set({ opacity: OPACITY_VALUE });
        }
      }

      this.canvas.element.requestRenderAll();
    },
    getCheckResource(resource) {
      return this.checkResource(resource);
    },
    async load_from_previous() {
      await this.getData(
        API_URL +
          "/detection/v2/object/?image=" +
          this.getLastImage +
          "&workspace=" +
          this.workspace,
        "loaded_objects",
        "results"
      );

      console.log("Load", this.getLastImage);
      console.log("Load", this.entities["loaded_objects"].length);

      if (this.entities["loaded_objects"].length > 0) {
        await this.eraseAll();
      }

      this.entities["loaded_objects"].forEach(element => {
        let objekt = this.createCanvasObject(
          element["data"],
          {
            id: element["detection_label"]["id"],
            name: element["detection_label"]["name"]
          },
          0,
          "OBJECT"
        );
        this.canvas.element.add(objekt);
        this.selected_dlabel = element["detection_label"]["id"];
        this.creatingEntity = objekt;
        this.createDetection();
      });

      this.is_predicting = false;
      this.makeToast(
        "success",
        this.entities["loaded_objects"].length + " objects were added."
      );

      console.log("EMIT ", "reload-list-" + this.image.id);
      setTimeout(() => {
        this.$eventBus.$emit("reload-list-" + this.image.id);
      }, 500);
    },
    predict: function() {
      if (this.selected_detection_task == null || this.is_predicting) {
        return;
      }

      this.$store.dispatch("update_last_detection_task", {
        task: this.selected_detection_task.id
      });

      this.is_predicting = true;
      axios
        .post(API_URL + "/detection/v2/detect", {
          records: [
            {
              _url: this.image.img_path
            }
          ],
          task_id: this.selected_detection_task.id
        })
        .then(
          response => {
            if ("records" in response.data) {
              let record = response.data["records"][0];
              record["_objects"].forEach(element => {
                let objekt = this.createCanvasObject(
                  element["bound_box"],
                  {
                    id: element["id"],
                    name: element["name"]
                  },
                  0,
                  "OBJECT"
                );
                this.canvas.element.add(objekt);
                this.selected_dlabel = element["id"];
                this.creatingEntity = objekt;
                this.createDetection();
              });

              this.is_predicting = false;
              this.makeToast(
                "success",
                record["_objects"].length + " objects were added."
              );
            }
          },
          error => {
            this.makeToast("danger", JSON.stringify(error.response.data));
            this.is_predicting = false;
          }
        );
    },
    makeToast(variant = null, content) {
      this.$bvToast.toast(content, {
        title: `Message`,
        variant: variant,
        toaster: "b-toaster-bottom-right",
        solid: true
      });
    },
    async getData(url, destination, field) {
      return new Promise(resolve => {
        if (!this.getCheckResource(ResourceEnum.DETECTION)) {
          // func();
          resolve(true);
          return;
        }

        if (this.data_loaded != DataLoadedEnum.LOADED) {
          return;
        }
        axios.get(url).then(
          response => {
            this.entities[destination] = response.data[field];
            resolve(true);
          },
          error => {
            resolve(false);
          }
        );
      });
    },
    createElement: function(url, data, callback, element, label) {
      axios.post(url, data).then(
        function(response) {
          if (callback) {
            callback(element, response.data, label);
          }
        },
        function(error) {
          console.log(error);
          this.makeToast(
            "danger",
            "Unable to create element (probably small object?)."
          );
        }.bind(this)
      );
    },
    async editElement(url, data) {
      await axios
        .put(url, data)
        .then(function(response) {}, function(error) {});
    },
    changeLabel: function(label) {
      let label_data = JSON.parse(
        JSON.stringify(this.active_object.label_data)
      );
      if (this.active_object.type == "DETECTION") {
        label_data["recognition_tasks"] = JSON.parse(
          JSON.stringify(this.tasks)
        );
        label_data["detection_label"] = { id: label.id, name: label.name };
      } else {
        label_data["segmentation_label"] = { id: label.id, name: label.name };
      }
      label_data["color"] = this.getLabel(label.id)["color"];

      this.active_object.set("label", label.name);
      this.active_object.set("id_label", label.id);
      this.active_object.set("label_data", label_data);

      if (this.active_object.type == "DETECTION") {
        this.editDetection(this.active_object);
      } else {
        this.editSegmentation(this.active_object);
      }
    },
    async deleteElement(url) {
      await axios.delete(url).then(function(response) {}, function(error) {});
    },
    fixCoordinate: function(size, limit) {
      if (size > limit) {
        return limit - 1;
      }
      return size;
    },
    createEntity(single = "deactivate") {
      console.log("CREATE TYPE", this.createType);
      if (this.createType == "DETECTION") {
        this.createDetection(single);
      } else if (this.createType == "POINT") {
        this.createPoint();
      } else {
        this.createSegmentation();
      }
    },
    createSegmentationPolygon: function() {
      if (this.canvas.pointArray.length > 2) {
        // closing polygon
        let points = new Array();
        this.canvas.pointArray.forEach(point => {
          points.push({
            x: point.left,
            y: point.top
          });
        });

        let polygon = new LabeledPoly(points, {
          stroke: "black",
          strokeDashArray: [this.strokeDashArray, this.strokeDashArray],
          strokeWidth: this.strokeWidth,
          fill: "white",
          hasBorders: true,
          hasControls: false,
          selectable: false
        });

        this.cancelSegmentation();
        this.canvas.element.add(polygon);
        this.canvas.element.setActiveObject(polygon);
        this.active_object = polygon;
        this.canvas.selected = true;

        this.canvas.isCreating = true;
        this.creatingEntity = polygon;
        this.showModal();
      }
    },
    createPoint: function() {
      let activeObj = this.creatingEntity;
      this.createElement(
        API_URL + "/annotate/v2/point/",
        {
          image: this.image.id,
          name: this.point_name,
          data: [
            Math.floor(this.active_object.left),
            Math.floor(this.active_object.top)
          ]
        },
        function(element, data, label) {
          element.set("id", data["id"]);
          element.set("name", data["name"]);

          this.active_object = element;
          this.canvas.isCreating = true;
          this.points.push(element);

          this.$root.$emit(
            "bv::hide::modal",
            "create_object_modal",
            "#btnShow"
          );
        }.bind(this),
        activeObj,
        null
      );
    },
    createSegmentation: function() {
      var activeObj = this.creatingEntity;
      if (this.selected_slabel == null) {
        return;
      }
      let points = [];
      let label = this.getLabel(this.selected_slabel);

      activeObj.get("points").forEach(function(element) {
        points.push(Math.floor(element.x));
        points.push(Math.floor(element.y));
      });

      this.createElement(
        API_URL + "/segmentation/v2/polygon/",
        {
          image: this.image.id,
          segmentation_label: this.selected_slabel,
          data: points
        },
        function(element, data, label) {
          data["color"] = label["color"];

          element.set("id", data["id"]);
          element.set("id_label", label.id);
          element.set("label_data", data);
          element.set("label", label.name);

          element.label_data.segmentation_label = {
            id: data["segmentation_label"]
          };
          this.segmentations.push(element);
          this.creatingEntity = null;
          this.$root.$emit(
            "bv::hide::modal",
            "create_object_modal",
            "#btnShow"
          );
          this.active_object = element;
          this.canvas.selected = true;
          this.canvas.isCreating = false;
        }.bind(this),
        activeObj,
        label
      );
    },
    createDetection: function(single = "deactivate") {
      let activeObj = this.creatingEntity;

      if (this.selected_dlabel == null) {
        return;
      }

      let tasks = this.deepCopy(this.tasks);
      let label = this.getLabel(this.selected_dlabel);

      for (let j = 0; j < tasks.length; j++) {
        for (let k = 0; k < tasks[j]["labels"].length; k++) {
          tasks[j]["labels"][k]["present"] = false;
        }
      }

      let border = this.getBorderSize();
      let bbox = [
        Math.floor(activeObj.left + border),
        Math.floor(activeObj.top + border),
        this.fixCoordinate(
          Math.floor(activeObj.getScaledWidth() + activeObj.left - border),
          this.image.img_width
        ),
        this.fixCoordinate(
          Math.floor(activeObj.getScaledHeight() + activeObj.top - border),
          this.image.img_height
        )
      ];

      this.createElement(
        API_URL + "/detection/v2/object/",
        {
          image: this.image.id,
          detection_label: label.id,
          data: bbox
        },
        function(element, data, label) {
          data["recognition_tasks"] = tasks;
          data["color"] = label.color;

          element.set("id", data["id"]);
          element.set("id_label", label.id);
          element.set("label_data", data);
          element.set("label", label.name);

          this.detections.push(element);
          this.creatingEntity = null;
          this.$root.$emit(
            "bv::hide::modal",
            "create_object_modal",
            "#btnShow"
          );
          if (single == "activate") {
            // if we create just single object now then activate it
            this.active_object = element;
            this.canvas.selected = true;
            this.$store.dispatch("update_last_detection_label", {
              label: label.id
            });
          } else if (single == "deactivate") {
            this.deactivateObject();
          } else {
            console.log("Do nothing CTRLV");
          }
        }.bind(this),
        activeObj,
        label
      );
    },
    cancelCreation: function() {
      let activeObj = this.creatingEntity;
      this.canvas.element.discardActiveObject();
      if (activeObj != null) {
        this.canvas.element.remove(activeObj);
      }

      this.cancelSegmentation();
      this.canvas.isCreating = false;
      this.canvas.active_object = null;
      this.creatingEntity = null;
      this.active_object = null;
      this.$root.$emit("bv::hide::modal", "create_object_modal", "#btnShow");
    },
    cancelSegmentation: function() {
      // segmentation polygon
      this.canvas.pointArray.forEach(point => {
        this.canvas.element.remove(point);
      });

      this.canvas.lineArray.forEach(line => {
        this.canvas.element.remove(line);
      });
      this.canvas.element
        .remove(this.canvas.activeShape)
        .remove(this.canvas.activeLine);
      this.canvas.activeLine = [];
      this.canvas.activeShape = false;
      this.canvas.pointArray = [];
      this.canvas.lineArray = [];
    },
    getBorderSize: function() {
      let border = this.strokeWidth;
      if (this.zoom < 1.0) {
        border *= this.zoom;
      }
      return border;
    },
    editSegmentation: function(segmentation) {
      let points = [];
      let matrix = segmentation.calcTransformMatrix();

      let transformedPoints = segmentation
        .get("points")
        .map(function(p) {
          return new fabric.Point(
            p.x - segmentation.pathOffset.x,
            p.y - segmentation.pathOffset.y
          );
        })
        .map(function(p) {
          return fabric.util.transformPoint(p, matrix);
        });

      transformedPoints.forEach(function(element) {
        points.push(Math.floor(element.x));
        points.push(Math.floor(element.y));
      });

      this.editElement(
        API_URL + "/segmentation/v2/polygon/" + segmentation.id + "/",
        {
          image: this.image.id,
          segmentation_label:
            segmentation.label_data["segmentation_label"]["id"],
          data: points,
          //bbox: bbox,
          meta_data: segmentation.label_data.meta_data,
          workspace: this.workspace
        }
      );
    },
    editPoint: function(point) {
      console.log("Editting point....");
      let position = [Math.round(point.left), Math.round(point.top)];
      console.log("Position", position);
      point.left = position[0];
      point.top = position[1];

      this.editElement(API_URL + "/annotate/v2/point/" + point.id + "/", {
        image: this.image.id,
        data: position,
        //meta_data: po.meta_data,
        workspace: this.workspace
      });
    },
    editDetection: function(detection) {
      let border = this.getBorderSize();

      let bbox = [
        Math.floor(detection.left + border),
        Math.floor(detection.top + border),
        Math.floor(
          Math.max(detection.getScaledWidth() - border, 16 + border) +
            detection.left
        ),
        Math.floor(
          Math.max(detection.getScaledHeight() - border, 16 + border) +
            detection.top
        )
      ];

      this.editElement(API_URL + "/detection/v2/object/" + detection.id + "/", {
        image: this.image.id,
        detection_label: detection.id_label,
        data: bbox,
        meta_data: detection.label_data.meta_data,
        workspace: this.workspace
      });

      this.active_object.label_data.data = bbox;
    },
    getLabel(label_id) {
      for (let m = 0; m < this.detection_labels.length; m++) {
        if (label_id == this.detection_labels[m]["id"]) {
          return this.detection_labels[m];
        }
      }
      for (let m = 0; m < this.segmentation_labels.length; m++) {
        if (label_id == this.segmentation_labels[m]["id"]) {
          return this.segmentation_labels[m];
        }
      }
    },
    async eraseAll() {
      for (let d = 0; d < this.detections.length; d++) {
        this.canvas.element.remove(this.detections[d]);
      }
      for (let d = 0; d < this.segmentations.length; d++) {
        this.canvas.element.remove(this.segmentations[d]);
      }

      await axios
        .delete(API_URL + "/detection/v2/object/image", {
          data: { image: this.image.id }
        })
        .then(function(response) {}, function(error) {});

      await axios
        .delete(API_URL + "/segmentation/v2/polygon/image", {
          data: { image: this.image.id }
        })
        .then(function(response) {}, function(error) {});

      this.detections = [];
      this.segmentations = [];

      setTimeout(() => {
        this.$eventBus.$emit("reload-list-" + this.image.id);
      }, 200);
    },
    async removeEntity(entity) {
      console.log("removing entity", entity);
      if (entity.type == "DETECTION") {
        let k = this.detections.indexOf(entity);
        this.detections.splice(k, 1);
        await this.deleteElement(
          API_URL +
            "/detection/v2/object/" +
            entity.id +
            "?workspace=" +
            this.workspace
        );
      } else if (entity.type == "POINT") {
        let k = this.points.indexOf(entity);
        this.points.splice(k, 1);
        await this.deleteElement(
          API_URL +
            "/annotate/v2/point/" +
            entity.id +
            "?workspace=" +
            this.workspace
        );
      } else {
        let k = this.segmentations.indexOf(entity);
        this.segmentations.splice(k, 1);
        await this.deleteElement(
          API_URL +
            "/segmentation/v2/polygon/" +
            entity.id +
            "?workspace=" +
            this.workspace
        );
      }

      if (this.active_object && this.active_object == entity) {
        this.active_object = null;
      }
      this.canvas.element.remove(entity);
    },
    showModal() {
      this.$root.$emit("bv::show::modal", "create_object_modal", "#btnShow");
    },
    changeMode(button) {
      for (let i in this.buttons) {
        if (this.buttons[i].mode != button.mode) {
          this.buttons[i].state = false;
        }
      }
      console.log("mode:", this.mode);
      this.mode = button.mode;
    },
    async changeTagToDetection(label) {
      this.removeTagFromDetection(label.id);
      this.addTagToDetection(label);
    },
    async addTagToDetection(label) {
      let label_obj = Object.assign({}, this.getLabelById(label.id));
      if (label.type == "value") {
        label_obj["value"] = label.value;
      }
      this.active_object.label_data.recognition_labels.push(label_obj);
      this.$eventBus.$emit("change-present-" + this.image_id);
      this.$forceUpdate();
      await this.$nextTick();
    },
    async removeTagFromDetection(label_id) {
      let k = this.active_object.label_data.recognition_labels.findIndex(
        label => label.id == label_id
      );
      this.active_object.label_data.recognition_labels.splice(k, 1);
      this.$eventBus.$emit("change-present-" + this.image_id);
      this.$forceUpdate();
      await this.$nextTick();
    },
    deepCopy(o) {
      return JSON.parse(JSON.stringify(o));
    },
    redrawStrokeWidth() {
      // redraws width of stroke based on zoom
      // this.strokeWidth = Math.max(3.0 / this.canvas.element.getZoom(), 2);
      if (this.canvas.element.getZoom() < 1.0) {
        this.strokeWidth = Math.max(3.0 / this.canvas.element.getZoom(), 2);
      } else {
        this.strokeWidth = Math.min(3.0 / this.canvas.element.getZoom(), 2);
      }

      console.log(
        "Redrawing stroke width",
        this.strokeWidth,
        this.canvas.element.getZoom()
      );
      let objects = this.canvas.element.getObjects();

      for (let j = objects.length - 1; j >= 0; j--) {
        if (
          objects[j].type == "DETECTION" ||
          objects[j].type == "SEGMENTATION" ||
          objects[j].type == "POINT"
        ) {
          objects[j].set({ strokeWidth: this.strokeWidth });
        }
      }

      // redraw circles of actual created polygon circles
      for (let k = this.canvas.pointArray.length - 1; k >= 0; k--) {
        this.canvas.pointArray[k].set({ radius: this.strokeWidth });
      }
      console.log("Point length...", this.points.length);
      for (let k = 0; k < this.points.length; k++) {
        this.points[k].set({ radius: this.strokeWidth * 2 });
      }

      // redraw lines of actual created polygon lines
      for (let l = this.canvas.lineArray.length - 1; l >= 0; l--) {
        this.canvas.lineArray[l].set({ stroke: this.strokeWidth });
      }

      this.canvas.element.requestRenderAll();
    },
    handle_keyboard_down(event) {
      if (event.keyCode == 27) {
        this.cancelCreation();
        this.canvas.element.discardActiveObject();
        this.canvas.element.requestRenderAll();
      } else if (
        this.canvas.isCreating == false &&
        (event.keyCode == 46 || event.keyCode == 8)
      ) {
        console.log("removing ...", this.canvas.isCreating);
        this.removeEntity(this.active_object);
      } else if (event.keyCode == 18) {
        this.last_mode = this.mode;
        this.mode = "ZOOM";
        document.getElementById("keyboard-icon").style.color = "red";
      } else if (event.keyCode == 13) {
        this.createSegmentationPolygon();
        if (this.creatingEntity) {
          this.createEntity("activate", this.creatingEntity);
        }
      } else if (event.keyCode == 86 && event.ctrlKey) {
        // copy object
        console.log("Control V down");
        let activeObj = this.canvas.element.getActiveObject();
        console.log("activeObj:", activeObj);

        let rect = this.createCanvasObject(
          activeObj.label_data["data"],
          activeObj.label_data["detection_label"],
          this.detections.length,
          "OBJECT"
        );
        rect.set("label_data", activeObj.label_data);
        // rect.set("id", activeObj.label_data["id"]);

        this.creatingEntity = rect;
        this.createDetection("donothing");
        this.detections.push(rect);
        this.canvas.element.add(rect).setActiveObject(activeObj);
        this.active_object = activeObj;
        this.canvas.element.requestRenderAll();
      } else {
        console.log("Key code: ", event.keyCode);
      }
    },
    deactivateObject() {
      this.active_object = null;
      this.canvas.selected = false;
      if (this.canvas.element) {
        this.canvas.element.discardActiveObject();
        this.canvas.element.requestRenderAll();
      }
    },
    handler_keyboard_up(event) {
      if (event.keyCode == 18 && this.mode == "ZOOM") {
        console.log("Zoom mode off");
        this.mode = this.last_mode;
        document.getElementById("keyboard-icon").style.color = null;
      }
    },
    selectObject(opt, item_id, type = null) {
      let pointer = null;
      console.log("Selecting object1 ", item_id, type);
      if (opt) {
        pointer = this.canvas.element.getPointer(opt);
      }

      if (this.canvas.selected) {
        let objects = shuffle(this.canvas.element.getObjects());
        let activeObj = this.canvas.element.getActiveObject();

        console.log("Active object:", activeObj);

        if (!activeObj) {
          this.canvas.selected = false;
          return;
        }

        // filter out objects that are not activeObj.id
        objects = objects.filter(function(obj) {
          return (
            ["DETECTION", "POINT", "SEGMENTATION"].includes(obj.type) &&
            obj.id != activeObj.id
          );
        });

        // if click on the corner of selected item
        if (activeObj.__corner) {
          return;
        }

        // select some different item
        for (let i = objects.length - 1; i >= 0; i--) {
          if (
            (objects[i].type == "DETECTION" ||
              objects[i].type == "POINT" ||
              (objects[i].type == "SEGMENTATION" &&
                (activeObj && activeObj != objects[i]))) &&
            (type == null || objects[i].type == type)
          ) {
            if (
              (item_id && item_id == objects[i].id) ||
              (pointer && objects[i].containsPoint(pointer, false, true))
            ) {
              console.log("selecting object 2:", objects[i].type);
              this.canvas.element.discardActiveObject();
              this.canvas.element.setActiveObject(objects[i]);
              let obj = this.canvas.element.getActiveObject();
              obj.set({
                fill: "rgba(255,255,255," + OPACITY_VALUE + ")",
                stroke: "black"
              });
              this.canvas.element.requestRenderAll();
              this.canvas.selected = true;
              this.active_object = obj;
              return;
            }
          }
        }
      }

      // check if there is already object on this position
      this.canvas.element.discardActiveObject();
      let objects = shuffle(this.canvas.element.getObjects());
      for (let z = objects.length - 1; z >= 0; z--) {
        if (
          (objects[z].type == "DETECTION" ||
            objects[z].type == "SEGMENTATION" ||
            objects[z].type == "POINT") &&
          (type == null || objects[z].type == type)
        ) {
          if (
            (item_id && item_id == objects[z].id) ||
            (pointer && objects[z].containsPoint(pointer, false, true))
          ) {
            objects[z].set({
              fill: "rgba(255,255,255," + OPACITY_VALUE + ")",
              stroke: "black"
            });
            console.log("selecting object 3:", objects[z].type);
            this.canvas.selected = true;
            this.active_object = objects[z];
            this.canvas.isCreating = false;
            this.canvas.element.setActiveObject(objects[z]);
            this.canvas.element.requestRenderAll();
            return;
          }
        }
      }
    },
    createCanvasObject(data, label, i, type) {
      let item = null;

      if (type == "POLYGON") {
        item = new LabeledPoly(data, {
          stroke: this.getLabel(label["id"])["color"],
          strokeWidth: this.strokeWidth,
          strokeDashArray: [this.strokeDashArray, this.strokeDashArray],
          label: label["name"] + " (" + i + ")",
          id_label: label["id"],
          fill: hexToRGB(this.getLabel(label["id"])["color"], OPACITY_VALUE),
          hasBorders: true,
          hasControls: false,
          selectable: false
        });
      } else if (type == "POINT") {
        console.log("POINT", data, label, i);
        item = new LabeledPoint({
          left: data[0],
          top: data[1],
          fill: "white",
          radius: this.strokeWidth * 1.3,
          stroke: "red",
          originX: "center",
          originY: "center",
          strokeWidth: this.strokeWidth,
          hasControls: false,
          selectable: false
        });
      } else {
        let border = this.getBorderSize();

        item = new LabeledRect({
          left: data[0] - border,
          top: data[1] - border,
          label: label["name"] + " (" + i + ")",
          id_label: label["id"],
          originX: "left",
          originY: "top",
          width: data[2] - data[0] + 2.0 * border,
          height: data[3] - data[1] + 2.0 * border,
          fill: hexToRGB(this.getLabel(label["id"])["color"], OPACITY_VALUE),
          strokeWidth: this.strokeWidth,
          stroke: this.getLabel(label["id"])["color"],
          transparentCorners: true,
          hasBorders: false,
          hasControls: true,
          hasRotatingPoint: false,
          cornerColor: "white",
          selectable: false
        });
      }

      if (type != "POINT") {
        item.setControlsVisibility({
          tr: true,
          bl: true,
          ml: false,
          mt: false,
          mr: false,
          mb: false,
          mtr: false
        });
      }
      item.setCoords();
      this.canvas.selected = true;
      return item;
    },
    async getObjectById(object_id) {
      let results = await axios.get(
        API_URL + "/detection/v2/object/" + object_id
      );
      return results;
    },
    createCanvas() {
      var canvas = new fabric.Canvas("detection");
      this.canvas.element = canvas;

      fabric.Image.fromURL(
        this.image["img_path"],
        async function(myImg) {
          this.canvas.element.setWidth(this.$refs.canvasContainer.clientWidth); // set the size of parent
          let minHeight = Math.min.apply(null, [
            this.windowHeight / 1.5,
            myImg.getScaledHeight()
          ]);
          this.canvas.element.setHeight(this.windowHeight - 165); //minHeight);

          let minHScale = minHeight / myImg.getScaledHeight();
          let min = Math.min.apply(null, [
            this.$refs.canvasContainer.clientWidth / myImg.getScaledWidth(),
            minHeight / myImg.getScaledHeight()
          ]);

          // zoom out the image if is bigger than view area
          if (min < 1.0) {
            this.canvas.element.setZoom(min);
            this.zoom = min;
            this.strokeWidth = Math.max(3.0 / canvas.getZoom(), 3);
            if (minHScale >= 1.0) {
              this.canvas.element.setHeight(minHeight * min);
            }
          }

          myImg.set({ left: 0, top: 0, mask: false });
          myImg.selectable = false;
          myImg.setCoords();
          canvas.add(myImg);

          this.image_canvas = myImg;

          if (this.image["foreground_mask"]) {
            fabric.Image.fromURL(
              this.image["foreground_mask"],
              function(myImg) {
                myImg.set({
                  left: 0,
                  top: 0,
                  opacity: 0.0,
                  mask: true,
                  selectable: false
                });
                myImg.setCoords();

                canvas.add(myImg);
                canvas.renderAll();
                canvas.requestRenderAll();
                canvas.bringToFront(myImg);

                this.foreground_mask = myImg;
                console.log("loaded foreground_mask image");
              }.bind(this)
            );
          }

          var square = new fabric.Rect({
            width: myImg.getScaledWidth(),
            height: myImg.getScaledHeight(),
            left: 0,
            top: 0,
            fill: "black",
            selectable: false,
            opacity: 0.05
          });
          canvas.add(square);

          for (let i = 0; i < this.entities["polygons"].length; i++) {
            let segmentation = this.entities["polygons"][i];
            segmentation["color"] = this.getLabel(
              segmentation["segmentation_label"]["id"]
            )["color"];

            let points = [];
            for (
              let index = 0;
              index < segmentation["data"].length;
              index += 2
            ) {
              points.push({
                x: segmentation["data"][index],
                y: segmentation["data"][index + 1]
              });
            }

            let poly = this.createCanvasObject(
              points,
              segmentation["segmentation_label"],
              i,
              "POLYGON"
            );

            poly.set("label_data", segmentation);
            poly.set("id", segmentation["id"]);
            canvas.add(poly);
            this.segmentations.push(poly);
          }

          for (let i = 0; i < this.entities["objects"].length; i++) {
            let detection = this.entities["objects"][i];
            detection["color"] = this.getLabel(
              detection["detection_label"]["id"]
            )["color"];
            detection["recognition_tasks"] = this.deepCopy(this.tasks);

            // load details of every object
            let result = await axios.get(
              API_URL + "/detection/v2/object/" + detection["id"]
            );
            detection["recognition_labels"] = result.data["recognition_labels"];

            for (let j = 0; j < detection["recognition_tasks"].length; j++) {
              for (
                let k = 0;
                k < detection["recognition_tasks"][j]["labels"].length;
                k++
              ) {
                this.$set(
                  detection["recognition_tasks"][j]["labels"][k],
                  "present",
                  false
                );

                if (
                  detection["recognition_labels"].indexOf(
                    detection["recognition_tasks"][j]["labels"][k].id
                  ) != -1
                ) {
                  this.$set(
                    detection["recognition_tasks"][j]["labels"][k],
                    "present",
                    true
                  );
                }
              }
            }

            let rect = this.createCanvasObject(
              detection["data"],
              detection["detection_label"],
              i,
              "OBJECT"
            );
            rect.set("label_data", detection);
            rect.set("id", detection["id"]);

            canvas.add(rect);
            this.detections.push(rect);
          }

          // create points
          for (let i = 0; i < this.entities["points"].length; i++) {
            let point = this.entities["points"][i];
            let pointo = this.createCanvasObject(
              point["data"],
              point,
              i,
              "POINT"
            );

            console.log("pointo", pointo);

            pointo.set("id", point["id"]);
            pointo.set("name", point["name"]);

            canvas.add(pointo);
            this.points.push(pointo);
          }

          console.log("LOAD:", this.job, this.getLastJob);
          if (
            this.job &&
            this.job.id == this.getLastJob &&
            this.getLastImage &&
            this.job.auto_complete
          ) {
            this.load_from_previous();
          }

          this.loading = false;
          this.redrawStrokeWidth();
          canvas.renderAll();
          canvas.requestRenderAll();
          this.hideItems(false);
        }.bind(this)
      );

      fabric.Object.prototype._renderStroke = function(ctx) {
        if (!this.stroke || this.strokeWidth === 0) {
          return;
        }
        if (this.shadow && !this.shadow.affectStroke) {
          this._removeShadow(ctx);
        }
        ctx.save();
        ctx.scale(1 / this.scaleX, 1 / this.scaleY);
        this._setLineDash(ctx, this.strokeDashArray, this._renderDashedStroke);
        this._applyPatternGradientTransform(ctx, this.stroke);

        ctx.stroke();
        ctx.restore();
      };

      fabric.Object.prototype._getTransformedDimensions = function(
        skewX,
        skewY
      ) {
        if (typeof skewX === "undefined") {
          skewX = this.skewX;
        }
        if (typeof skewY === "undefined") {
          skewY = this.skewY;
        }
        const dimX = this.width / 2,
          dimY = this.height / 2;

        let points = [
          { x: -dimX, y: -dimY },
          { x: dimX, y: -dimY },
          { x: -dimX, y: dimY },
          { x: dimX, y: dimY }
        ];
        const transformMatrix = this._calcDimensionsTransformMatrix(
          skewX,
          skewY,
          false
        );
        for (let i = 0; i < points.length; i++) {
          points[i] = fabric.util.transformPoint(points[i], transformMatrix);
        }
        const bbox = fabric.util.makeBoundingBoxFromPoints(points);
        return {
          y: bbox.height,
          x: bbox.width
        };
      };

      canvas.on(
        "mouse:wheel",
        function(options) {
          // Zooming
          if (this.mode == "ZOOM") {
            let delta = options.e.deltaY;
            let pointer = canvas.getPointer(options.e);
            let zoom = canvas.getZoom();
            zoom = zoom + delta / 200;
            // CHECK that the zooom has some limits
            if (zoom > 15) zoom = 15;
            if (zoom < 0.05) zoom = 0.05;
            this.zoom = zoom;
            canvas.zoomToPoint(
              { x: options.e.offsetX, y: options.e.offsetY },
              zoom
            );
            options.e.preventDefault();
            options.e.stopPropagation();

            // Redraw all strokes of bounding boxes
            this.redrawStrokeWidth();
          }
        }.bind(this)
      );

      canvas.on(
        "mouse:dblclick",
        function(options) {
          this.selectObject(options);
        }.bind(this)
      );

      canvas.on(
        "mouse:down",
        function(options) {
          let pointer = this.canvas.element.getPointer(options.e);

          if (this.clickOutsideImage(options)) {
            if (!this.isCreating) {
              this.active_object = null;
              this.canvas.element.discardActiveObject();
              this.canvas.selected = false;
            }
            return;
          }

          // We are creating new item
          console.log("MODE", this.mode);
          if (this.mode == "POINT") {
            this.selectObject(options, null, "POINT");
            if (this.active_object) {
              console.log("Active object", this.active_object);
              return;
            }

            let item = new LabeledPoint({
              left: Math.round(pointer.x),
              top: Math.round(pointer.y),
              fill: "white",
              radius: this.strokeWidth * 1.3,
              originX: "center",
              originY: "center",
              stroke: "red",
              strokeWidth: this.strokeWidth,
              hasControls: false,
              selectable: false
            });

            this.canvas.element.add(item).setActiveObject(item);
            this.creatingEntity = item;
            this.active_object = item;
            this.showModal();
            this.canvas.isCreating = true;
          } else if (this.mode == "SEGMENTATION") {
            // We are creating new item
            if (this.segmentation_labels.length == 0) {
              return;
            }

            if (
              (options.target &&
                this.canvas.pointArray.length > 2 &&
                options.target.id == this.canvas.pointArray[0].id) ||
              (this.canvas.pointArray.length > 2 && options.e.button > 0)
            ) {
              // closing polygon
              this.createSegmentationPolygon();
            } else {
              if (options.e.button != 0) {
                return;
              }

              // UNCOMMENT THIS IF WE WANT TO SELECT POLYGON ON FIRST CLICK
              // if (!this.canvas.isCreating) {
              //   this.selectObject(options);
              // }

              if (this.active_object) {
                return;
              }

              this.canvas.isCreating = true;
              let random = Math.floor(Math.random() * (9999999 - 99 + 1)) + 99;
              let id = new Date().getTime() + random;
              let circle = new fabric.Circle({
                radius: this.strokeWidth * 2,
                fill: "rgba(255, 255, 255, 0.3)",
                stroke: "#333333",
                strokeWidth: this.strokeWidth,
                left: pointer.x,
                top: pointer.y,
                selectable: false,
                hasBorders: false,
                hasControls: false,
                originX: "center",
                originY: "center",
                id: id,
                objectCaching: false
              });
              if (this.canvas.pointArray.length == 0) {
                circle.set({
                  fill: "red"
                });
              }
              let points = [pointer.x, pointer.y, pointer.x, pointer.y];

              let line = new fabric.Line(points, {
                strokeWidth: this.strokeWidth,
                strokeDashArray: [this.strokeDashArray, this.strokeDashArray],
                fill: "purple",
                stroke: "black",
                class: "line",
                originX: "center",
                originY: "center",
                selectable: false,
                hasBorders: false,
                hasControls: false,
                evented: false,
                objectCaching: false
              });
              if (this.canvas.activeShape) {
                let pos = canvas.getPointer(options.e);
                let points = this.canvas.activeShape.get("points");
                points.push({
                  x: Math.round(pos.x),
                  y: Math.round(pos.y)
                });
                let polygon = new LabeledPoly(points, {
                  stroke: "#333333",
                  strokeWidth: this.strokeWidth,
                  fill: "purple",
                  opacity: OPACITY_VALUE,
                  selectable: false,
                  hasBorders: false,
                  hasControls: false,
                  evented: false,
                  objectCaching: false
                });
                canvas.remove(this.canvas.activeShape);
                canvas.add(polygon);
                this.canvas.activeShape = polygon;
                canvas.renderAll();
              } else {
                let polyPoint = [
                  {
                    x: Math.round(pointer.x),
                    y: Math.round(pointer.y)
                  }
                ];
                let polygon = new LabeledPoly(polyPoint, {
                  stroke: "#333333",
                  strokeWidth: this.strokeWidth,
                  fill: "purple",
                  opacity: OPACITY_VALUE,
                  selectable: false,
                  hasBorders: false,
                  hasControls: false,
                  evented: false,
                  objectCaching: false
                });
                this.canvas.activeShape = polygon;
                canvas.add(polygon);
              }
              this.canvas.activeLine = line;

              this.canvas.pointArray.push(circle);
              this.canvas.lineArray.push(line);

              canvas.add(line);
              canvas.add(circle);
            }
          }
          // We click on the canvas and we are not zooming
          else if (this.mode == "DETECTION" && !this.canvas.isCreating) {
            if (this.detection_labels.length == 0) {
              return;
            }

            if (
              !(
                pointer.x < this.image.img_width &&
                pointer.y < this.image.img_height
              )
            ) {
              return;
            }

            if (this.canvas.selected) {
              return;
            }

            // Filter
            let f = fabric.Image.filters;
            let filter = new f.Contrast({ contrast: 10 });

            // Storing original position
            this.canvas.origX = pointer.x;
            this.canvas.origY = pointer.y;
            this.canvas.isCreating = true;

            // Creating new bounding box
            let rect = new LabeledRect({
              left: this.canvas.origX,
              top: this.canvas.origY,
              label: "",
              originX: "left",
              originY: "top",
              width: pointer.x - this.canvas.origX,
              height: pointer.y - this.canvas.origY,
              angle: 0,
              transparentCorners: true,
              hasBorders: false,
              hasControls: true,
              hasRotatingPoint: false,
              cornerColor: "white",
              opacity: 1.0,
              selectable: false
            });

            // Showing just some corners for resizing/scaling
            rect.setControlsVisibility({
              tr: true,
              bl: true,
              ml: false,
              mt: false,
              mr: false,
              mb: false,
              mtr: false
            });

            // Adds bounding box to the canvas
            this.canvas.element.add(rect).setActiveObject(rect);
          }

          // Zooming and Dragging
          if (this.mode == "ZOOM") {
            this.canvas.isDragging = true;
            this.canvas.lastPosX = options.e.clientX;
            this.canvas.lastPosY = options.e.clientY;
          }
        }.bind(this)
      );

      /**
       * Whenever we move mouse over the canvas.
       */
      canvas.on(
        "mouse:move",
        function(options) {
          let pointer = this.canvas.element.getPointer(options.e);

          if (this.detection_labels.length == 0) {
            return;
          }

          if (this.clickOutsideImage(options)) {
            return;
          }

          if (this.mode == "SEGMENTATION") {
            if (
              this.canvas.activeLine &&
              this.canvas.activeLine.class == "line"
            ) {
              let pointer = canvas.getPointer(options.e);
              this.canvas.activeLine.set({ x2: pointer.x, y2: pointer.y });

              let points = this.canvas.activeShape.get("points");
              points[this.canvas.pointArray.length] = {
                x: pointer.x,
                y: pointer.y
              };
              this.canvas.activeShape.set({
                points: points
              });
              canvas.renderAll();
            }
          } else if (this.canvas.isCreating && this.mode == "DETECTION") {
            let activeObj = this.canvas.element.getActiveObject();

            activeObj.strokeWidth = this.strokeWidth;
            activeObj.set({
              fill: "rgba(255,255,255," + OPACITY_VALUE + ")",
              stroke: "black"
            });

            // Switching two points so left is always on left side
            if (this.canvas.origX > pointer.x) {
              activeObj.set({ left: Math.abs(pointer.x) });
            }

            // Same with top point
            if (this.canvas.origY > pointer.y) {
              activeObj.set({ top: Math.abs(pointer.y) });
            }

            // CHECK: We are crossing left border
            if (pointer.x < 0) {
              activeObj.set({ left: 0.0 });
            } else {
              activeObj.set({
                width: Math.abs(
                  this.canvas.origX - Math.min(pointer.x, this.image.img_width)
                )
              });
            }

            // CHECK: We are crossing top border
            if (pointer.y < 0) {
              activeObj.set({ top: 0.0 });
            } else {
              activeObj.set({
                height: Math.abs(
                  this.canvas.origY - Math.min(pointer.y, this.image.img_height)
                )
              });
            }

            activeObj.setCoords();
            this.canvas.element.requestRenderAll();
          }

          // Zooming and Dragging
          if (this.canvas.isDragging && this.mode == "ZOOM") {
            // transform viewport when dragging
            var e = options.e;
            this.canvas.element.viewportTransform[4] +=
              e.clientX - this.canvas.lastPosX;
            this.canvas.element.viewportTransform[5] +=
              e.clientY - this.canvas.lastPosY;
            this.canvas.lastPosX = e.clientX;
            this.canvas.lastPosY = e.clientY;

            // if we have objects we need to refresh it
            // because of some scaling bug in fabricjs
            let objects = this.canvas.element.getObjects();
            for (let z = objects.length - 1; z >= 0; z--) {
              objects[z].setCoords();
            }

            this.canvas.element.requestRenderAll();
          }
        }.bind(this)
      );

      /**
       * Whenever we release mouse click on the canvas.
       */
      canvas.on(
        "mouse:up",
        function(options) {
          if (this.detection_labels.length == 0) {
            return;
          }

          // only in segmentation mode, check the active object
          if (this.mode == "SEGMENTATION" && this.clickOutsideImage(options)) {
            if (!this.isCreating) {
              this.active_object = null;
              this.canvas.element.discardActiveObject();
              this.canvas.selected = false;
            }
            return;
          }

          console.log("MOUSE:UP", this.mode);

          if (this.mode != "ZOOM") {
            let activeObj = this.canvas.element.getActiveObject();
            if (
              this.mode == "SEGMENTATION" &&
              (activeObj || this.canvas.isCreating)
            ) {
              if (!this.canvas.isCreating && activeObj.type == "SEGMENTATION") {
                this.editSegmentation(activeObj);
              }
            } else {
              // If we already have some active object
              if (activeObj) {
                // If we have some small active object then delete it
                if (
                  activeObj.width < 20 &&
                  activeObj.height < 20 &&
                  (this.mode == "DETECTION" || this.mode == "SEGMENTATION")
                ) {
                  this.canvas.element.discardActiveObject();
                  this.canvas.element.remove(activeObj);

                  // And after delete try to select new object
                  this.selectObject(options);
                } else {
                  // If we finish creating new Bounding Box
                  if (
                    this.canvas.isCreating &&
                    (this.mode == "DETECTION" ||
                      this.mode == "SEGMENTATION" ||
                      this.mode == "POINT")
                  ) {
                    if (this.mode != "POINT") {
                      activeObj.set("label", "new label");
                    }
                    activeObj.setCoords();
                    this.creatingEntity = activeObj;
                    this.canvas.element.requestRenderAll();
                    this.showModal();
                    if (this.mode == "POINT") {
                      return;
                    }
                  } else {
                    // we are moving the object somehow
                    if (activeObj.type == "DETECTION") {
                      this.editDetection(activeObj);
                    } else if (activeObj.type == "SEGMENTATION") {
                      this.editSegmentation(activeObj);
                    } else if (activeObj.type == "POINT") {
                      this.editPoint(activeObj);
                    }
                  }
                }
              } else {
                this.active_object = null;
                this.canvas.element.discardActiveObject();
                this.canvas.selected = false;

                if (this.mode != "SEGMENTATION" && !this.canvas.isCreating) {
                  // Nothing is active, try to select new object
                  this.selectObject(options);
                }
              }

              // We are finishing with creating mode
              this.canvas.isCreating = false;
            }
          }

          // Zooming and dragging
          if (this.mode == "ZOOM") {
            this.canvas.isDragging = false;
          }
        }.bind(this)
      );

      // this disable multiple selection
      canvas.selection = false;

      // this enable resizing in x and y direction
      canvas.uniScaleTransform = true;

      /**
       * This function is called whenever we deselect already created object.
       */
      canvas.on(
        "before:selection:cleared",
        function(e) {
          let object = this.canvas.element.getActiveObject();
          if (object.id_label) {
            let label = this.getLabel(object.id_label);
            object.set({
              fill: hexToRGB(label["color"], OPACITY_VALUE),
              stroke: label["color"]
            });
            if (object.type == "SEGMENTATION") {
              object.set({
                strokeDashArray: [this.strokeDashArray, this.strokeDashArray]
              });
            }
          } else {
            object.set({ fill: "white", stroke: "red" });
          }
        }.bind(this)
      );

      canvas.on(
        "selection:cleared",
        function(e) {
          // when we have something selected and holding alt key for zoom
          // and we click on some different object than we should reselect
          // the same object
          if (this.mode == "ZOOM") {
            this.canvas.element.setActiveObject(this.active_object);
            this.active_object = this.canvas.element.getActiveObject();
            this.active_object.setCoords();
            this.canvas.selected = true;
            this.canvas.element.requestRenderAll();
          }
        }.bind(this)
      );

      /**
       * This function is called whenever we scale already existed bounding box object.
       */
      canvas.on(
        "object:scaling",
        function(o) {
          let object = this.canvas.element.getActiveObject();

          if (object.left + object.getScaledWidth() > this.image.img_width) {
            object.set({ scaleX: this.scaleBefore.x });
          }
          if (object.top + object.getScaledHeight() > this.image.img_height) {
            object.set({ scaleY: this.scaleBefore.y });
          }
          if (object.top < 0.0) {
            object.set({ top: 0.0, scaleY: this.scaleBefore.y });
          }
          if (object.left < 0.0) {
            object.set({ left: 0.0, scaleX: this.scaleBefore.x });
          }

          // we need to store the scale which was before
          this.scaleBefore.x = object.scaleX;
          this.scaleBefore.y = object.scaleY;
        }.bind(this)
      );

      /**
       * This function is called whenever we move already created bounding box object.
       */
      canvas.on(
        "object:moving",
        function(o) {
          this.canvas.isCreating = false;
          let object = this.canvas.element.getActiveObject();

          if (
            object.type == "DETECTION" &&
            object.left + object.getScaledWidth() > this.image.img_width
          ) {
            object.set({
              left: this.image.img_width - object.getScaledWidth()
            });
          } else if (
            object.type == "POINT" &&
            object.left > this.image.img_width
          ) {
            object.set({ left: this.image.img_width - 1 });
          }

          if (
            object.type == "DETECTION" &&
            object.top + object.getScaledHeight() > this.image.img_height
          ) {
            object.set({
              top: this.image.img_height - object.getScaledHeight()
            });
          } else if (
            object.type == "POINT" &&
            object.top > this.image.img_height
          ) {
            object.set({ top: this.image.img_height - 1 });
          }

          if (object.left < 0.0) {
            object.set({ left: 0.0 });
          }
          if (object.top < 0.0) {
            object.set({ top: 0.0 });
          }
        }.bind(this)
      );
    }
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
      });
    });
    await this.load_entities();

    document.addEventListener("keydown", this.handle_keyboard_down);
    document.addEventListener("keyup", this.handler_keyboard_up);

    if (this.getLastDetectionTask) {
      this.selected_detection_task = this.detection_tasks.find(
        element => element.id == this.getLastDetectionTask
      );
    } else {
      if (this.detection_tasks.length) {
        this.selected_detection_task = this.detection_tasks[0];
      }
    }

    if (this.getLastDetectionLabel) {
      this.selected_dlabel = this.getLastDetectionLabel;
    } else {
      if (this.detection_labels.length > 0) {
        this.selected_dlabel = this.detection_labels[0].id;
      }
    }
    if (this.segmentation_labels.length > 0) {
      this.selected_slabel = this.segmentation_labels[0].id;
    }
  },
  created() {
    this.$eventBus.$on("Image-workflow-change", data => {
      this.mode = data.type;
      console.log("this mode:", this.mode);
      if (this.mode != "SELECTION") {
        // if we are switching modes then discard active object
        this.deactivateObject();
      }
    });
    this.$eventBus.$on(
      "add-label-detection-" + this.image.id,
      this.addTagToDetection
    );
    this.$eventBus.$on(
      "change-labelvalue-detection-" + this.image.id,
      this.changeTagToDetection
    );
    this.$eventBus.$on(
      "del-label-detection-" + this.image.id,
      this.removeTagFromDetection
    );
    this.$eventBus.$on("select-item-" + this.image.id, this.selectItem);
  },
  beforeDestroy() {
    console.log("BEFORE DESTROY WTF", "del-label-detection-" + this.image.id);
    this.$eventBus.$off("Image-workflow-change");
    this.$eventBus.$off("add-label-detection-" + this.image.id);
    this.$eventBus.$off("change-labelvalue-detection-" + this.image.id);
    this.$eventBus.$off("del-label-detection-" + this.image.id);
    this.$eventBus.$off("select-item" + this.image.id);
    document.removeEventListener("keydown", this.handle_keyboard_down);
    document.removeEventListener("keyup", this.handler_keyboard_up);
  },
  watch: {
    async data_loaded() {
      await this.load_entities();
    },
    show_mask_value() {
      this.foreground_mask.set({ opacity: this.show_mask_value });
      this.canvas.element.requestRenderAll();
    },
    active_object() {
      // whenever we changed active object we need to emit signal to the parent
      if (
        this.active_object == null ||
        this.active_object.type == "DETECTION"
      ) {
        this.$emit("selectDetectionEvent", { object: this.active_object });
      }
      if (
        this.active_object == null ||
        this.active_object.type == "SEGMENTATION"
      ) {
        this.$emit("selectSegmentationEvent", { object: this.active_object });
      }
      if (this.active_object == null || this.active_object.type == "POINT") {
        this.$emit("selectPointEvent", { object: this.active_object });
      }
    },
    messageAddTag() {
      this.addTagToDetection(this.messageAddTag);
    },
    messageRemoveTag() {
      this.removeTagFromDetection(this.messageRemoveTag);
    },
    messageRemovePoint() {
      this.removeEntity(this.active_object);
    },
    messageRemoveDetection() {
      this.removeEntity(this.active_object);
    },
    messageRemoveSegmentation() {
      this.removeEntity(this.active_object);
    },
    messageUpdateMeta() {
      this.active_object.label_data.meta_data = this.messageUpdateMeta.meta_data;
      this.editDetection(this.active_object);
    },
    messageChangeSegmentationLabel() {
      this.changeLabel(this.messageChangeSegmentationLabel.label);
    },
    messageChangeDetectionLabel() {
      this.changeLabel(this.messageChangeDetectionLabel.label);
    }
  }
};
</script>

<style scoped>
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 55px; /* Vertically center the text there */
  background-color: #ffffff;
}
</style>
